import React from 'react'

import Layout from "../components/Layout"
import Menu from "../components/Menu"
import SEO from "../components/seo"
import PrivacyPolicy from "../components/PrivacyPolicy"

import { ThemeProvider } from 'styled-components'
import General from '../styles/theme'

const Privacy = () => (
  <ThemeProvider theme={General}>
    <Layout>
    <SEO title="Privacy Policy" />
    <Menu />
    <div>
      <PrivacyPolicy/>
    </div>
    </Layout>
  </ThemeProvider>
)

export default Privacy